<template>
  <div class="expt_view">
    <iframe :src="view_url" scrolling="auto" frameborder="0"></iframe>
  </div>
</template>

<script>
import { getOpenLabHost } from 'api/experiment/diy'
export default {
  components: {},
  data() {
    return {
      course_uuid: '',
      view_url: ''
    }
  },
  async created() {
    if (this.$route.params.course_uuid != undefined) {
      this.course_uuid = this.$route.params.course_uuid;
    }
    await this.getExptViewUrl(this.course_uuid)
  },
  mounted() {
  },
  methods: {
    async getExptViewUrl(course_uuid) {
      const that = this;
      await getOpenLabHost().then(result => {
        if (result.succeed) {
          const host_url = result.url
          that.view_url = host_url + `/open/api/course/view/${course_uuid}/`;
        } else {
          window.location.href = "/404";
        }
      }).catch(err => {
        if (!err.__CANCEL__) {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        }
      });
    }
  }
  }
</script>

<style lang="scss" scoped>
  .expt_view {
    display:flex;
    height:100%;
  }
  iframe {
    width:100vw;
    margin:-20px;
    flex:1;
  }
</style>
